import React from 'react';

import Icon from './Icon';

export default function FeedIcon({
  className = '',
  text = 'Feed',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 12C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14C6.32608 14 7.59785 14.5268 8.53553 15.4645C9.47322 16.4021 10 17.6739 10 19C10 19.2652 10.1054 19.5196 10.2929 19.7071C10.4804 19.8946 10.7348 20 11 20C11.2652 20 11.5196 19.8946 11.7071 19.7071C11.8946 19.5196 12 19.2652 12 19C12 17.1435 11.2625 15.363 9.94975 14.0503C8.63699 12.7375 6.85652 12 5 12ZM5 8C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9C4 9.26522 4.10536 9.51957 4.29289 9.70711C4.48043 9.89464 4.73478 10 5 10C7.38695 10 9.67613 10.9482 11.364 12.636C13.0518 14.3239 14 16.6131 14 19C14 19.2652 14.1054 19.5196 14.2929 19.7071C14.4804 19.8946 14.7348 20 15 20C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19C15.9923 16.084 14.8353 13.2886 12.78 11.22C10.7114 9.16472 7.91601 8.00775 5 8ZM15.61 8.39C12.7888 5.58754 8.97655 4.01018 5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6C8.44781 6 11.7544 7.36964 14.1924 9.80761C16.6304 12.2456 18 15.5522 18 19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19C19.9898 15.0235 18.4125 11.2112 15.61 8.39Z" />
      </svg>
    </Icon>
  );
}
